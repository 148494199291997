import styled from 'styled-components';
import color from './colors';

export const H1 = styled.h1`
  color: ${color.black};
  font-size: 4.6rem;
  line-height: 1.2;
  font-weight: 700;
`;

export const H2 = styled.h2`
  color: ${color.orange};
  font-size: 3.6rem;
  line-height: 1.2;
  font-weight: 600;
`;

export const H3 = styled.h3`
  color: ${color.black};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.2;
`;

export const H4 = styled.h4`
  color: ${color.black};
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.2;
`;

export const H5 = styled.h5`
  color: ${color.black};
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
`;
