import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import Global from '../components/Global';
import { H2 } from '../constants/fonts';
import breakpoints from '../constants/breakpoints';
import color from '../constants/colors';

const Wrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
`;

const PageContent = styled.div`
  text-align: center;
  a {
    color: ${color.orange};
  }
  a:hover {
    color: ${color.orange};
  }
`;
const ErrorNumber = styled.p`
  font-size: 8rem;
  margin: 0;
  text-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);
  @media (min-width: ${breakpoints.md}) {
    font-size: 12rem;
  }
`;

const NotFoundPage = ({ data }) => (
  <>
    <Global />
    <Container>
      <Wrapper>
        <PageContent>
          <ErrorNumber>404</ErrorNumber>
          <H2>Brak strony o powyższym adresie.</H2>
          <p>Wróć do:</p>
          <Link to="/">{data.site.siteMetadata.contact.website}</Link>
        </PageContent>
      </Wrapper>
    </Container>
  </>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              website
            }
          }
        }
      }
    `}
    render={data => <NotFoundPage data={data} />}
  />
);
